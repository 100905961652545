import { createApp } from 'vue';
import JobsearchNewJobsearchSwitch from 'JIX/components/jobsearch/JobsearchNewJobsearchSwitch.vue';

const stash = window.Stash['jobsearch/new_jobsearch_switch'];

const newJobsearchSwitchContainer = document.getElementById('new-jobsearch-switch');
if (newJobsearchSwitchContainer) {
    createApp(JobsearchNewJobsearchSwitch, {
        seenFeatureTip: Boolean(stash.new_jobsearch.seen_feature_tip)
    }).mount(newJobsearchSwitchContainer);
}
